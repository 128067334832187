<script>
import {ErpSelect, ErpSField} from 'uloc-vue-plugin-erp'
import ERow from "@/components/layout/components/Row"
import ECol from "@/components/layout/components/Col"
import {listTiposParte} from "@/domain/processos/services"
import DateInput from "@/reuse/input/Date"
import ClienteProcessoInput from "@/components/processos/components/cliente/input/ClienteProcessoInput"
import {newEnvolvido, updateEnvolvido, find} from "@/domain/processos/services/envolvido"
import ProcessoMixins from "@/components/processos/components/processos/mixins/ProcessoMixins"
import {datePtToEn, convertDate} from "@/utils/date"
import WindowLoading from "@/components/layout/window/Loading"
const MAX_LIMIT_LOAD_SELECTS = 500

const mock = {
  id: null,
  envolvido: true,
  nomeacao: null,
  data: null,
  indicacao: null,
  dataProtocolo: null,
  pessoa: null,
  processo: null,
  tipo: null
}

export default {
  name: 'ProdutividadeEtapaPagamentoWindow',
  components: {
    WindowLoading,
    ClienteProcessoInput,
    DateInput,
    ERow,
    ECol,
    ErpSelect,
    ErpSField
  },
  props: ['options', 'router', 'id', 'processoId'],
  mixins: [ProcessoMixins],
  data() {
    return {
      loading: !!this.id,
      success: false,
      parte: JSON.parse(JSON.stringify(mock)),
      cache: {
        tiposParte: {
          list: [],
          loading: false,
          selected: null
        },
        simNao: {
          list: [
            { label: 'Sim', value: true },
            { label: 'Não', value: false },
          ]
        }
      },
      fakeCliente: {
        id: '',
        descricao: ''
      }
    }
  },
  created () {
    this.loadTiposParte()
    this.parte.processo = this.processoId
    this.load()
  },
  computed: {
    isSemCliente () {
      return !Object.values(this.fakeCliente).every(v => v)
    },
    isLeiloeiro () {
      if (!this.parte.tipo) return false
      let find = this.cache.tiposParte.list.find(l => l.value === Number(this.parte.tipo))
      console.log(this.cache.tiposParte.list)
      console.log(this.parte.tipo)
      console.log(find)
      console.log(find.label.toLowerCase())
      return find && find.label.toLowerCase() === 'leiloeiro'
    }
  },
  methods: {
    load () {
      !!this.id && find(this.id)
          .then(response => {
            this.parte = response.data
            this.parte.tipo = this.parte.tipo?.id
            if (this.parte.data) {
              this.parte.data = convertDate(this.parte.data).replace(/ .*/, '')
            }
            if (this.parte.dataProtocolo) {
              this.parte.dataProtocolo = convertDate(this.parte.dataProtocolo).replace(/ .*/, '')
            }
            this.$nextTick(() => {
              this.adicionarCliente(this.parte.pessoa)
            })
            this.parte.processo = this.processoId
          })
          .catch((error) => {
            console.log('ERRO', error)
            this.alertApiError(error)
          })
          .finally(() => {
            this.loading = false
          })
    },
    save() {
      this.loading = true
      const data = JSON.parse(JSON.stringify(this.parte))
      data.pessoa = this.fakeCliente?.id || this.fakeCliente
      if (!data.pessoa || this.isSemCliente) {
        this.$uloc.notify({
          type: 'negative',
          message: 'Necessário informar um cliente'
        })
        this.loading = false
        return
      }
      data.data = datePtToEn(data.data)
      data.dataProtocolo = datePtToEn(data.dataProtocolo)
      const method = this.id ? updateEnvolvido(this.id, data) : newEnvolvido(data)
      method
          .then(response => {
            this.parte.id = response.data.id
            this.dg()
            this.$uloc.window.emit(this.$root.wid, 'updated', this.props)
          })
          .catch((error) => {
            this.alertApiError(error)
            console.log(error)
          })
          .finally(() => {
            this.loading = false
          })
    },
    loadTiposParte () {
      const parameters = [MAX_LIMIT_LOAD_SELECTS, 1, '&sortBy=nome'];
      this.loadCache('tiposParte', listTiposParte, parameters)
    },
    adicionarCliente (cliente) {
      if (!cliente) return
      console.log('Cliente: ', cliente)
      this.fakeCliente.id = cliente.id
      this.fakeCliente.descricao = cliente.name
    }
  }
}
</script>

<template>
  <form @submit.prevent="save" class="w-m-content" v-if="!loading">
    <div class="w-m-header">
      <p class="font-14 no-margin no-select" style="color: #293e52">Adicionar Cliente</p>
    </div>
    <div class="w-m-body e-input-modern label-size-2 size1">
      <div class="font-11">
        <e-row>
          <e-col style="max-width: 160px">
            <erp-s-field
                label="Tipo de Envolvido"
            >
              <erp-select
                  simple-border
                  v-model="parte.tipo"
                  :options="cache.tiposParte.list"
                  :loading="cache.tiposParte.loading"
              />
            </erp-s-field>
          </e-col>
          <e-col class="m-l-sm" style="max-width: 350px">
            <erp-s-field
                label="Adicionar cliente:"
            >
              <cliente-processo-input v-model="fakeCliente" @selecionaCliente="adicionarCliente($event, 'cliente')" />
            </erp-s-field>
          </e-col>
        </e-row>

        <e-row class="m-t-md">
          <e-col style="max-width: 160px">
            <erp-s-field
                label="Indicação"
            >
              <erp-select
                  simple-border
                  :disable="!isLeiloeiro"
                  v-model="parte.indicacao"
                  :options="cache.simNao.list"
              />
            </erp-s-field>
          </e-col>

          <e-col class="m-l-sm" style="max-width: 160px">
            <date-input label="Data protocolo" v-model="parte.dataProtocolo" :disable="!isLeiloeiro" />
          </e-col>

          <e-col class="m-l-sm" style="max-width: 160px">
            <erp-s-field
                label="Nomeação"
            >
              <erp-select
                  simple-border
                  v-model="parte.nomeacao"
                  :disable="!isLeiloeiro"
                  :options="cache.simNao.list"
              />
            </erp-s-field>
          </e-col>

          <e-col class="m-l-sm" style="max-width: 160px">
            <date-input label="Data" v-model="parte.data" :disable="!isLeiloeiro" />
          </e-col>
        </e-row>
      </div>
    </div>
    <div class="w-m-footer text-right footer-modern">
      <u-btn @click="$uloc.window.close($root.wid)" label="Cancelar" no-caps
             :color="'negative'"
             text-color="white"
             class="b-radius-3px btn-white m-r-sm"
      />
      <u-btn :loading="loading" type="submit" label="Salvar" no-caps
             :color="'positive'"
             class="b-radius-3px"
      />
      <u-btn @click="$uloc.window.close($root.wid)" label="Fechar" no-caps
             :color="'white'"
             text-color="grey-8"
             class="b-radius-3px btn-white m-l-sm"
      />
    </div>
  </form>
  <window-loading v-else></window-loading>
</template>

